import {appCalls, appCallCallbacks, cookieConsentKeys, cookieConsentValues} from './constants-enumerate';
import {UnityAppCall} from '@aoflmkt/app-call';
import {environmentTypeEnumerate} from '@aofl/server-environment';

/**
 *
 * @param {String} url
 * @return {Object}
 */
const parseURL = (urlParams = '') => {
  const params = urlParams;
  const host = window.location.host;

  if (typeof urlParams.href !== 'undefined' && urlParams.href !== '') {
    params.href = urlParams.href.replace(/https:\/\/|http:\/\//gi, '');

    if (params.href.charAt(0) === '/' && typeof urlParams.target !== 'undefined') {
      params.href = host + params.href;
    }
  }

  if (typeof urlParams.schema === 'undefined') {
    params.schema = 'https';

    if (environmentTypeEnumerate.DEV) {
      params.schema = 'http';
    }
  }
  return params;
};


/**
 *
 */
class AppCallService {
  /**
   *
   * @param {String} url
   */
  static navigate(urlParams) {
    const params = parseURL(urlParams);

    UnityAppCall.send(appCalls.NAVIGATE, params);
  }

  /**
   *
   * @param {Object} params
   */
  static purchase(params) {
    UnityAppCall.send(appCalls.PURCHASE, params);
  }

  /**
   *
   */
  static restoreTransaction() {
    UnityAppCall.send(appCalls.RESTORE);
  }

  /**
   *
   * @param {String} eventToken
   */
  static adjustEvent(eventToken = '', customParams = {}) {
    if (localStorage.getItem(cookieConsentKeys.COOKIE_PREFERENCES) === cookieConsentValues.COOKIES_DEFAULT) {
      window.postMessage({
        request: appCallCallbacks.ADJUST_EVENT
      }, window.parent.origin);
    }

    const customParameters = JSON.stringify(customParams);
    UnityAppCall.send(appCalls.ADJUST_EVENT, {eventToken, customParameters});
  }

  /**
   *
   * @static
   * @param {String} token
   */
  static routeToApp(token) {
    UnityAppCall.send(appCalls.ROUTE_TO_APP, {token});
  }

  /**
   *
   */
  static getSession() {
    UnityAppCall.send(appCalls.SESSION_GET);
  }

  /**
   *
   * @param {String} type
   * @param {Object} payload
   */
  static logBIEvent(type = '', payload = {}) {
    payload = JSON.stringify(payload);
    UnityAppCall.send(appCalls.LOG_BI_EVENT, {type, payload});
  }
}

export {
  AppCallService
};
