import Cookie from 'js-cookie';
import {cookies} from '../constants-enumerate';
import {CaptchaService} from '../captcha-service';

/**
 *
 */
class JsonFormatter {
  /**
   * @param {Object} payload
   * @return {Object}
   */
  static pack({args}) {
    const body = new FormData();
    const headers = new Headers();

    body.append('arguments', JSON.stringify(args) || '""');
    body.append('token', Cookie.get(cookies.TOKEN));
    body.append('device_id', Cookie.get(cookies.DEVICE_ID));
    headers.append('Accept', 'application/json');

    return {
      method: 'POST',
      headers,
      body,
      credentials: 'include',
      cache: 'no-cache'
    };
  }
  /**
   *
   * @param {Promise} response
   * @return {Promise}
   */
  static async unpack(response) {
    const data = await response.json();

    if (response.status === 403 && typeof data.payload === 'undefined') {
      data.captcha = true;
      try {
        CaptchaService.parseCaptchaResponse(data);
      } catch (e) {
      }
    }

    if (data.success === 'TRUE' || data.success === 'WARNING') {
      const currentCookie = Cookie.get(cookies.TOKEN);
      if (currentCookie !== data.token) {
        Cookie.set(cookies.TOKEN, data.token, cookies.CONFIG);
      }
      /* istanbul ignore next */
      const currentAppSessionId = Cookie.get(cookies.APP_SESSION_ID);
      if (data.payload.app_session_id && currentAppSessionId !== data.payload.app_session_id) {
        /** @todo remove when api is fixed */
        Cookie.set(cookies.APP_SESSION_ID, data.payload.app_session_id, cookies.CONFIG);
      }
      return data;
    }

    return Promise.reject(data);
  }
}

export default JsonFormatter;
