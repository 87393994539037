/** eslint-disable */
import {Rotations} from '@aofl/rotations';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {rotation, cacheNamespaces, cookies, queryParamKeys, apis} from './constants-enumerate';
import rotationConditions from './__config/rotation-conditions';
import ROTATION_CONFIG from './__config/rotation-config';
import routesConfig from './__config/routes.js';
import {resourceEnumerate} from './resource-enumerate';
import Cookies from 'js-cookie';

/**
 * Rotation config object retrieved from S3
 */

const THIRTY_DAYS = 2592000;

const FALLBACK_CONFIG = {
  'baseline_version': '0000',
  'conditions': {},
  'qualification_order': {},
  'versions': {},
  'weights': {}
};

/**
 *
 */
class RotationsService {
  /**
   * Determines whether or not to qualify a user for a given rotation based on
   * the last time they visited or global exclusion conditions
   * @param {Function} doRequalify
   * @param {Boolean} forceRequalify
   * @param {Object} exclusionOverrideOpts {wdio: false, intl: false}
   */
  static async rotationRequalifyCheck(doRequalify, forceRequalify = false, exclusionOverrideOpts = {}) {
    const allowRequest = await this.checkGlobalExclusionConditions(exclusionOverrideOpts);
    if (!allowRequest) return false;

    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    const timeLastVisited = timeLastVisitedData.getItem(cacheNamespaces.TIME_LAST_VISITED);
    if (forceRequalify || currentTime > timeLastVisited + THIRTY_DAYS) {
      return doRequalify();
    }

    return false;
  }
  /**
   * Checks to see if any global exclusion conditions are met, returning false if the request should be excluded
   * @param {Object} exclusionOverrideOpts
   */
  static async checkGlobalExclusionConditions(exclusionOverrideOpts) {
    try {
      const globalExclusionConditions = this.getGlobalExclusionConditions();
      const exclusionConditions = Object.assign({}, globalExclusionConditions, exclusionOverrideOpts);
      for (const key in exclusionConditions) {
        // Overrides will set key to false if the condition is meant to be ignored
        if (exclusionConditions[key] === false) continue;
        if (
          typeof exclusionConditions[key]?.condition === 'function' &&
          await exclusionConditions[key]?.condition() // eslint-disable-line
        ) {
          return false;
        }
      }
    } catch (err) {}

    return true;
  }
  /**
   * Returns an object of global exclusion conditions
   * @return {Object}
   */
  static getGlobalExclusionConditions() {
    return {
      wdio: {
        condition: () => {
          return Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) === 'baseline';
        }
      },
      intl: {
        condition: async () => {
          const resources = await resourceEnumerate.get(apis.ABCMOUSE_MARKETING);
          return resources?.payload?.country_code !== 'US';
        }
      }
    };
  }
  /**
   *
   * Persisting the last time the user visited the site. For use in rotation conditions
   */
  static setTimeLastVisited() {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    timeLastVisitedData.setItem(cacheNamespaces.TIME_LAST_VISITED, currentTime);
  }
  /**
   *
   * @param {Object} config
   * @param {String} version
   * @return {Object}
   */
  static setVersion(config, version) {
    const currentWeights = config.weights;
    let validVersion = false;

    for (const key in currentWeights) {
      if (!Object.hasOwnProperty.call(currentWeights, key)) continue;
      for (const id in currentWeights[key]) {
        if (!Object.hasOwnProperty.call(currentWeights[key], id)) continue;
        currentWeights[key][id] = 0;

        if (id === version) {
          validVersion = true;
          currentWeights[key][id] = 1;
        }
      }
    }

    if (!validVersion) {
      throw new Error(`The requested rotation version "${version}" does not exist`);
    }

    config.weights = currentWeights;
    return config;
  }
  /**
   * Returns a routes object based on either the retrieved rotation config
   * AWS, or the fallback config if an exception is thrown.
   *
   */
  static async getRoutes() {
    let routes = {};
    let rotationConfig = ROTATION_CONFIG;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const rotationVersion = urlSearchParams.get(queryParamKeys.ROTATION_VERSION);

    try {
      if (rotationVersion) {
        rotationConfig = this.setVersion(ROTATION_CONFIG, rotationVersion);
      }

      const rotationInstance = new Rotations(rotation.READINGIQ, routesConfig, rotationConfig, rotationConditions);
      routes = await rotationInstance.getRoutes();
    } catch (err) {
      const rotationInstance = new Rotations(rotation.READINGIQ, routesConfig, FALLBACK_CONFIG, rotationConditions);
      routes = await rotationInstance.getRoutes();
    }

    return routes;
  }
}

export {
  RotationsService
};

