import {queryParamKeys, pages} from '../constants-enumerate';

/**
 * This object is used in init-router-service and will match a vanity
 * url with the appropriate affiliate url.
 */

export const vanityUrls = {
  '/coupon': `/?${queryParamKeys.SOURCE_TAG}=aff::ctm:print1`,
  '/groupon': `/redeem?${queryParamKeys.SOURCE_TAG}=aff::groupon:2months`,
  '/blackfriday': `/subscription/holiday/offer/79d8e34/?${queryParamKeys.SOURCE_TAG}=other:pr:19:2`,
  '/newworlds': `${pages.HOME}`,
  '/subscription/seasonal/offer/11ee057/': `${pages.HOME}`,
  '/subscription/seasonal/offer/11ee057': `${pages.HOME}`,
  '/subscription/offer/6ap5f7a/': `${pages.HOME}`,
  '/subscription/offer/6ap5f7a': `${pages.HOME}`,
  '/subscription/offer/743959d/': `${pages.HOME}`,
  '/subscription/offer/743959d': `${pages.HOME}`,
};
