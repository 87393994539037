import {Sdo, storeInstance, state} from '@aofl/store';
import {cookies, countryCodes} from '../../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';

if (Cookies.get(cookies.GDPR) !== 'hide') {
  countryCodes.hasOwnProperty(Cookies.get(cookies.COUNTRY_CODE)) ? Cookies.set(cookies.GDPR, 'show', cookies.CONFIG) : ''; // eslint-disable-line
}

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'gdpr-banner';

/**
 * @extends {Sdo}
 */
class GdprBanner extends Sdo {
  /**
   * @readonly
   * @type {String}
   */
  static namespace = NAMESPACE

  /**
   * @type {Boolean}
   */
  @state()
  visible = Cookies.get(cookies.GDPR) === 'show';
}

const gdprBannerSdo = new GdprBanner();
storeInstance.addState(gdprBannerSdo);

export {
  gdprBannerSdo
};
