import {ApiService} from './api-service';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {cacheNamespaces, apis, environment, cookieConsentKeys, cookieConsentValues} from './constants-enumerate';
import {CampaignService} from './campaign-service';
import {ServerTime} from '@aoflmkt/server-time';
import {readingIqTrackerQueue} from './readingiq-tracker-queue';

const cacheManager = new CacheManager(cacheNamespaces.CAMPAIGN_PIXELS_LOG, cacheTypeEnumerate.LOCAL);

/**
 * Service to fire pixels using an iframe
 * @class
 */
class PixelService {
  /**
   * Constructor
   */
  constructor() {
  }

  /**
   *
   * @param {String} event (landing|conversion|sign-up)
   * @return {Promise}
   */
  static async fire(event) {
    if (environment.IN_APP) return;

    if (localStorage.getItem(cookieConsentKeys.COOKIE_PREFERENCES) === cookieConsentValues.COOKIES_DEFAULT) {
      await PixelService.fireCampaignPixel(event);
    }
    await PixelService.fireGlobalPixel(event);
  }

  /**
   *
   * @param {String} event
   */
  static trackEvent(action, event, type) {
    const campaignId = CampaignService.getCampaignInfo()?.id;

    const trackerPayload = {
      event_type: `AOFL.READINGIQ_MARKETING.V1.marketing-pixel-fire-${action}`,
      payload: {
        event,
        type,
        campaignId
      }
    };

    return readingIqTrackerQueue.enqueueEvent(trackerPayload);
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireGlobalPixel(event) {
    if (navigator.globalPrivacyControl) return;
    const globalPixels = await import('./__config/pixels');

    if (globalPixels?.default()[event]) {
      PixelService.trackEvent('before', event, 'global');
      await PixelService.attachPixel(globalPixels?.default()[event]);
      PixelService.trackEvent('after', event, 'global');
    }
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireCampaignPixel(event) {
    if (navigator.globalPrivacyControl) return;
    const campaignInfo = CampaignService.getCampaignInfo();

    if (campaignInfo.id !== null && !PixelService.hasPixelFire(campaignInfo.id, event)) {
      const response = await ApiService.getCampaignPixel(event);
      if (response.payload && response.payload.pixel) {
        PixelService.trackEvent('before', event, 'campaign');
        await PixelService.attachPixel(response.payload.pixel);
        PixelService.trackEvent('after', event, 'campaign');
        PixelService.logPixel(campaignInfo.id, event);
      }
    }
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   * @return {Boolean}
   */
  static hasPixelFire(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    let hasPixelFire = false;
    const expiration = ServerTime.getAdjustedTime(apis.READINGIQ_MARKETING) - (3600*24*30);

    if (pixelLog[campaignId]
      && pixelLog[campaignId][event]
      && pixelLog[campaignId][event] > expiration
    ) {
      hasPixelFire = true;
    }
    return hasPixelFire;
  }

  /**
   *
   * @return {Object}
   */
  static getPixelLog() {
    const campaignPixels = cacheManager.getItem('pixelLog');
    let pixelLog = {};

    if (campaignPixels) {
      try {
        pixelLog = JSON.parse(campaignPixels);
      } catch (e) {}
    }
    return pixelLog;
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   */
  static logPixel(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    pixelLog[campaignId] = pixelLog[campaignId] || {};
    pixelLog[campaignId][event] = ServerTime.getAdjustedTime(apis.READINGIQ_MARKETING);
    cacheManager.setItem('pixelLog', JSON.stringify(pixelLog));
  }

  /**
   * Creates and iframe and resolves the promise
   * after the iframe has done loading
   * @param {String} pixel
   * @return {Promise}
   */
  static attachPixel(pixel) {
    return new Promise((resolve, reject) => {
      const iFrame = document.createElement('iframe');
      iFrame.width = 1;
      iFrame.height = 1;
      iFrame.frameborder = 0;
      iFrame.style.display = 'none';

      document.body.appendChild(iFrame);

      iFrame
        .addEventListener('load', function() {
          resolve();
        });

      iFrame.contentWindow.document.open();
      iFrame.contentWindow.document.write(pixel);
      iFrame.contentWindow.document.close();
    });
  }

  /**
   *
   * @param {String} page
   */
  static trackPageLoad(page) {
    try {
      // Google Analytics
      window.ga('send', 'pageview', page);

      // Bing UET
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', {'page_path': page});
    } catch (e) {}
  }
}

export {
  PixelService
};
