import {storeInstance} from '@aofl/store/dist/legacy';
import {deepAssign} from '@aofl/object-utils';
import {sdoNamespaces} from '../../../../../modules/constants-enumerate';

const sdo = {
  namespace: sdoNamespaces.MAIN_LAYOUT,
  mutations: {
    init(payload = {}) {
      return Object.assign({
        overlay: {
          full: false,
          header: false,
          content: false,
          footer: false
        },
        scroll: {
          top: 0,
          left: 0,
          freeze: false,
          scrollToTop: false
        }
      }, payload);
    },
    setOverlay(subState, full) {
      return deepAssign(subState, 'overlay', {
        full
      });
    },
    setHeaderOverlay(subState, header) {
      return deepAssign(subState, 'overlay', {
        header
      });
    },
    setContentOverlay(subState, content) {
      return deepAssign(subState, 'overlay', {
        content
      });
    },
    setFooterOverlay(subState, footer) {
      return deepAssign(subState, 'overlay', {
        footer
      });
    },
    freezeScroll(subState, {top, left}) {
      return deepAssign(subState, 'scroll', {
        top,
        left,
        freeze: true
      });
    },
    scrollToTop(subState, {top, left, scrollToTop}) {
      return deepAssign(subState, 'scroll', {
        top,
        left,
        freeze: true,
        scrollToTop
      });
    },
    unFreezeScroll(subState) {
      return deepAssign(subState, 'scroll', {
        freeze: false
      });
    }
  }
};
storeInstance.addState(sdo);
