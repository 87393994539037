import {SimpleModals} from '../../../modules/simple-modal';
import {storeInstance} from '@aofl/store/dist/legacy';
import {sdoNamespaces} from '../../../modules/constants-enumerate';

/**
 *
 * @class ModalService
 */
class ModalService {
  /**
   *
   * @static
   * @param {Object} modalsConfig
   */
  static addModals(modalsConfig) {
    SimpleModals.addModals(modalsConfig);
  }

  /**
   *
   * @static
   * @param {String} modalName
   */
  static show(modalName, scrollOptions) {
    storeInstance.commit({
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'scrollToTop',
      payload: {
        top: window.pageYOffset,
        left: window.pageXOffeset,
        scrollToTop: scrollOptions?.scrollToTop ? scrollOptions.scrollToTop : false
      }
    }, {
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'setOverlay',
      payload: true
    });
    SimpleModals.show(modalName);
    document.activeElement.blur();
  }

  /**
   *
   * @static
   */
  static hide() {
    SimpleModals.hide();
    storeInstance.commit({
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'setOverlay',
      payload: false
    }, {
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'unFreezeScroll'
    });
  }
}

export {
  ModalService
};
