import {resourceEnumerate} from './resource-enumerate';
import {TrackerQueue} from '@aoflmkt/tracker-queue';
import {ServerTime} from '@aoflmkt/server-time';
import {isMobile} from '@aoflmkt/is-mobile';
import {ApiService} from './api-service';
import {apis, cookies} from './constants-enumerate';
import Cookies from 'js-cookie';
import {uuid} from '@aofl/uuid';

/**
 *
 * @extends {TrackerQueue}
 */
class ReadingIqTrackerQueue extends TrackerQueue {
  /**
   * Creates an instance of ReadingIqTrackerQueue.
   */
  constructor() {
    super();

    this.interval = null;

    this.resetInterval();

    /* istanbul ignore next */
    window.addEventListener('beforeunload', () => {
      this.processQueue();
    });
  }

  /**
   * Overrides the base class method and decorates the event object with additional data.
   *
   * @param {Object} event
   */
  async enqueueEvent(event) {
    await resourceEnumerate.get(apis.READINGIQ_MARKETING);
    event.timestamp = ServerTime.getAdjustedTime(apis.READINGIQ_MARKETING);
    event.event_id = uuid();
    event.context = {
      log_type: 'web_client',
      log_version: 1,
      device_id: Cookies.get(cookies.DEVICE_ID),
      token: Cookies.get(cookies.TOKEN),
      app_session_id: Cookies.get(cookies.APP_SESSION_ID) /* eslint-disable-line */
    };
    super.enqueueEvent(event);
  }

  /**
   *
   */
  sparseAssign(target, ...sources) {
    if (target !== Object(target)) {
      return target;
    }
    sources.forEach((source) => {
      if (source !== Object(source)) {
        return;
      }
      Object.keys(source).forEach((key) => {
        const sourceVal = source[key];
        if (sourceVal === Object(sourceVal)) {
          if (!Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = Array.isArray(sourceVal) ? [] : {};
          }
          this.sparseAssign(target[key], sourceVal);
        }
        else if (typeof sourceVal !== 'undefined') {
          target[key] = sourceVal;
        }
      });
    });
    return target;
  }

  /**
   * Decorates the event object with additional data for v2 events before calling `enqueueEvent` on
   * the base class.
   *
   * @param {Object} event
   */
  async enqueueEventV2(_event) {
    await resourceEnumerate.get(apis.READINGIQ_MARKETING);
    const defaultEventData = {
      event_type: null,
      payload: {},
      context: {
        device_id: Cookies.get(cookies.DEVICE_ID),
        device_info: {
          form_factor: isMobile.phone ? 'phone' : isMobile.tablet ? 'tablet' : 'desktop',
          user_agent: window.navigator.userAgent
        },
        domain: window.location.hostname,
        log_source: 'web_client',
        log_version: '2.0',
        user: {
          master_uuid: null,
          profile_uuid: null,
          user_uuid: null,
          user_description: null
        },
        user_agent: window.navigator.userAgent
      },
      timestamp: ServerTime.getAdjustedTime(apis.READINGIQ_MARKETING)
    };
    const event = this.sparseAssign(defaultEventData, _event);
    super.enqueueEvent(event);
  }

  /**
   * Clears the current interval if one exists and starts a new interval and calls processQueue every 10s.
   */
  resetInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.processQueue();
    }, 10000);
  }

  /**
   * Calls send queue and passes a callback function that tries to make a call to event_log.
   *
   * @return {Promise}
   */
  processQueue() {
    return this.sendQueue((queue) => {
      return ApiService.eventLog({events: queue});
    });
  }
}

const readingIqTrackerQueue = new ReadingIqTrackerQueue();

export {
  readingIqTrackerQueue
};
