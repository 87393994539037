import {sdoNamespaces, cacheNamespaces} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {deepAssign} from '@aofl/object-utils';
import {storeInstance} from '@aofl/store/dist/legacy';

const sdo = {
  namespace: sdoNamespaces.SESSION,
  mutations: {
    init() {
      const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
      const userData = userSessionData.getItem(cacheNamespaces.USER_SESSION);

      return {
        isLoggedIn: false,
        userInfo: {
          userUuid: userData?.userUuid || '',
          userAccountType: userData?.userAccountType || '',
          nextStep: userData?.nextStep || '',
          isActive: userData?.isActive || ''
        }
      };
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setUserInfo(subState, payload) {
      return Object.assign({}, subState, {userInfo: payload});
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setUserAccountType(subState, payload) {
      return deepAssign(subState, 'userInfo', {
        userAccountType: payload
      });
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setUserUuid(subState, payload) {
      return deepAssign(subState, 'userInfo', {
        userUuid: payload
      });
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setNextStep(subState, payload) {
      return deepAssign(subState, 'userInfo', {
        nextStep: payload
      });
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setIsActive(subState, payload) {
      return deepAssign(subState, 'userInfo', {
        isActive: payload
      });
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setLoggedIn(subState) {
      return Object.assign({}, subState, {isLoggedIn: true});
    },
    /**
     *
     * @param {Object} subState
     * @param {Boolean} payload
     * @return {Object}
     */
    setLoggedOut(subState) {
      return Object.assign({}, subState, {isLoggedIn: false});
    }
  }
};

storeInstance.addState(sdo);

export {
  sdo
};
