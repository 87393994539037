import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {accessibilityMixin} from '../accessibility-mixin';
import {routerInstance} from '@aofl/router';
import {pages} from '../constants-enumerate';

/**
 * @summary CdivPage
 * @extends {AoflElement}
 */
@customElement('cdiv-page')
class CdivPage extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'cdiv-page';

  /**
   * @param {Event} e
   */

  /**
   *
   */
  goHomeClick() {
    return routerInstance.navigate(pages.HOME);
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default CdivPage;
