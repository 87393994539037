import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {mapStatePropertiesMixin} from '@aofl/map-state-properties-mixin';
import {storeInstance} from '@aofl/store/dist/legacy';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {sdoNamespaces} from '../../../../modules/constants-enumerate';
/**
 * @summary MainLayout
 * @extends {AoflElement}
 */
@customElement('main-layout')
class MainLayout extends mapStatePropertiesMixin(isMobileMixin(AoflElement)) {
  /**
   * Creates an instance of MainLayout.
   */
  constructor() {
    super();

    this.storeInstance = storeInstance;
    this.mediaQueries = [
      window.matchMedia('(max-width: 823px) and (orientation: landscape)'),
      window.matchMedia('(max-width: 568px)')
    ];
  }

  /**
   *
   * @private
   * @readonly
   */
  static is = 'main-layout';

  /**
   *
   * @return {Object}
   */
  @property({type: Boolean})
  'full-height' = false;
  @property({type: Boolean, attribute: false})
  'headerOverlay' = false;
  @property({type: Boolean, attribute: false})
  'contentOverlay' = false;
  @property({type: Boolean, attribute: false})
  'footerOverlay' = false;

  /**
   *
   */
  resetDDMenu() {
    const state = storeInstance.getState();
    if (state[sdoNamespaces.MAIN_LAYOUT].ddMenu === true) {
      storeInstance.commit({
        namespace: sdoNamespaces.MAIN_LAYOUT,
        mutationId: 'setContentOverlay',
        payload: false
      }, {
        namespace: sdoNamespaces.MAIN_LAYOUT,
        mutationId: 'setFooterOverlay',
        payload: false
      });
    }
  }

  /**
   *
   * @param {*} options
   */
  renderScrollFreeze(options) {
    const bodyPosition = window.getComputedStyle(document.body).getPropertyValue('position');
    if (options.scrollToTop) {
      document.body.style.position = 'fixed';
      document.body.style.top = '0px';
      document.body.style.left = '0px';
    } else if (options.freeze && bodyPosition !== 'fixed') {
      this.bodyPosition = bodyPosition;
      document.body.style.position = 'fixed';
      document.body.style.top = (-1 * options.top) + 'px';
      document.body.style.left = (-1 * options.left) + 'px';
    } else if (!options.freeze && bodyPosition === 'fixed') {
      document.body.style.position = this.bodyPosition;
      window.scrollTo(options.left, options.top);
    }
  }

  /**
   *
   */
  mapStateProperties() {
    const state = storeInstance.getState();

    if (state[sdoNamespaces.MAIN_LAYOUT].overlay.full === true) {
      this.classList.add('show-overlay');
    } else {
      this.classList.remove('show-overlay');
    }

    this.renderScrollFreeze(state[sdoNamespaces.MAIN_LAYOUT].scroll);
    this.headerOverlay = state[sdoNamespaces.MAIN_LAYOUT].overlay.header;
    this.contentOverlay = state[sdoNamespaces.MAIN_LAYOUT].overlay.content;
    this.footerOverlay = state[sdoNamespaces.MAIN_LAYOUT].overlay.footer;
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainLayout;
