const isWebPQualified = () => {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) { // eslint-disable-line
  // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

const isNotQualified = !isWebPQualified();

export {
  isNotQualified
};
