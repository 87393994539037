import {template} from './template';
import styles from './template.css';
import {AoflElement, customElement, property} from '@aofl/element';
import {routerInstance} from '@aofl/router';
import {AppCallService} from '../app-call-service';
import {environment} from '../constants-enumerate';
import {resolvePage} from '../page-load-utils';

const LEADING_SLASH_REGEX = /^\//;
const PUBLIC_PATH_REGEX = new RegExp('^' + __webpack_public_path__); // eslint-disable-line

/**
 *
 * @class LinkToElement
 * @extends {AoflElement}
 */
@customElement('link-to')
class LinkToElement extends AoflElement {
  /**
   *
   * Creates an instance of LinkToElement.
   */
  constructor() {
    super();
    this.href = '';
    this.orientationChanged = false;

    window.addEventListener('orientationchange', () => {
      this.orientationChanged = true;
    });
  }

  /**
   *
   * @readonly
   */
  static is = 'link-to';

  /**
   *
   * @return {Object}
   */
  @property({type: String})
  href = '';

  @property({type: Boolean, attribute: 'use-parent-gate'})
  useParentGate;

  @property({type: String})
  target;

  @property({type: Boolean, attribute: 'tab-focus-disabled'})
  'tabFocusDisabled' = false;

  @property({type: Boolean})
  'tab-outline-blue' = false;

  @property({type: Boolean})
  'tab-outline-white' = false;

  /**
   * @param {Event} e
   * @return {void}
   */
  clickHandler(e) {
    e.preventDefault();
    const linkToConditions = this.createLinkToConditions();
    const resolvedLink = resolvePage(linkToConditions);
    resolvedLink.resolve();
  }

  /**
   * @return {Object}
   */
  createLinkToConditions() {
    const teacherUrl = /teachers/;
    const params = {};
    const origin = window.location.origin;

    const iosInApp = environment.FLUTTER_IOS || environment.UNITY_IOS;
    const androidInApp = environment.FLUTTER_ANDROID || environment.UNITY_ANDROID;
    const hasParentGateAndTarget = this.useParentGate && this.target;
    const hasParentGateOnly = this.useParentGate && !this.target;
    const hasTargetOnly = !this.useParentGate && this.target;
    const isTeacher = teacherUrl.test(this.href) || this.orientationChanged;

    let url = origin + this.href;

    if (/https/.exec(this.href) !== null) {
      url = this.href;
    }

    return {
      iosParentGateAndTarget: {
        condition: () => {
          return iosInApp && hasParentGateAndTarget;
        },
        resolve: () => {
          params.useParentGate = this.useParentGate;
          params.target = this.target;
          params.href = url;
          AppCallService.navigate(params);
        }
      },
      iosParentGateOnly: {
        condition: () => {
          return iosInApp && hasParentGateOnly;
        },
        resolve: () => {
          params.useParentGate = this.useParentGate;
          params.href = this.href;
          AppCallService.navigate(params);
        }
      },
      iosTargetOnly: {
        condition: () => {
          return iosInApp && hasTargetOnly;
        },
        resolve: () => {
          params.target = this.target;
          params.href = url;
          AppCallService.navigate(params);
        }
      },
      teacher: {
        condition: () => {
          return isTeacher;
        },
        resolve: () => {
          window.location = this.href;
        }
      },
      androidParentGateAndTarget: {
        condition: () => {
          return androidInApp && hasParentGateAndTarget;
        },
        resolve: () => {
          params.target = this.target;
          params.href = url;
          AppCallService.navigate(params);
        }
      },
      androidTargetOnly: {
        condition: () => {
          return androidInApp && hasTargetOnly;
        },
        resolve: () => {
          params.target = this.target;
          params.href = url;
          AppCallService.navigate(params);
        }
      },
      defaultParentGateAndTarget: {
        condition: () => {
          return hasParentGateAndTarget && !iosInApp && !androidInApp;
        },
        resolve: () => {
          window.open(this.href, this.target);
        }
      },
      defaultTargetOnly: {
        condition: () => {
          return hasTargetOnly && !iosInApp && !androidInApp;
        },
        resolve: () => {
          window.open(this.href, this.target);
        }
      },
      defaultNoTarget: {
        condition: () => {
          return true;
        },
        resolve: () => {
          routerInstance.navigate(this.href);
        }
      }
    };
  }

  /**
   *
   * @param {String} name
   * @param {*} oldValue
   * @param {*} newValue
   */
  attributeChangedCallback(name, oldValue, newValue) {
    super.attributeChangedCallback(name, oldValue, newValue);
    if (name === 'href' && /https/.exec(this.href) === null) {
      const href = this.href.replace(PUBLIC_PATH_REGEX, '').replace(LEADING_SLASH_REGEX, '');
      this.href = environment.PUBLIC_PATH + href; // eslint-disable-line
    }
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();

    if (this['tab-outline-blue']) {
      this.classList.add('tab-outline-blue');
    } else {
      this.classList.remove('tab-outline-blue');
    }

    if (this['tab-outline-white']) {
      this.classList.add('tab-outline-white');
    } else {
      this.classList.remove('tab-outline-white');
    }
  }


  /**
   *
   * @return {Object}
   */
  render() {
    if (location.pathname === this.href) {
      this.classList.add('active');
    } else {
      this.classList.remove('active');
    }

    return super.render(template, [styles]);
  }
}

export default LinkToElement;
