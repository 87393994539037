import {apis, cookies} from '../constants-enumerate';
import Cookies from 'js-cookie';
import {getDeviceId} from '../device';

const marketingRequest = new FormData();
const savedDeviceId = getDeviceId();
const savedToken = Cookies.get(cookies.TOKEN);

if (savedToken) marketingRequest.append('token', savedToken);
if (savedDeviceId) marketingRequest.append('device_id', savedDeviceId);

const resourceEnumerateConfig = {
  apis: {
    [apis.READINGIQ_MARKETING]: {
      url: `https://${location.hostname}/ws/marketing/0.1/json/Resource/Enumerate/init`,
      requestOptions: {
        cache: 'no-cache',
        method: 'POST',
        credentials: 'include',
        body: marketingRequest
      },
      developmentVariables() { // return an object that is passed into the develpomentConfig function
        return {
          host: location.hostname
        };
      },
      stageVariables() { // return an object that is passed into the stageConfig function
        return {
          host: location.hostname
        };
      },
      invalidateCache() { // This function is invoked before each get() call and it controls whether or not a cached version is returned
        return false;
      }
    }
  },
  developmentConfig: () => import('./dev-api-config'), // lazy-load dev config
  stageConfig: () => import('./stage-api-config') // lazy-load stage config
};

export default resourceEnumerateConfig;

