import resourceEnumerateConfig from './__config/resource-enumerate';
import {ResourceEnumerate} from '@aofl/resource-enumerate';
import {apis} from './constants-enumerate';
import {ServerTime} from '@aoflmkt/server-time';
import {environment as serverEnvironment} from './server-environment';
import {readingIqTrackerQueue} from './readingiq-tracker-queue';
import JsonFormatter from './__config/json-formatter';
import Cookies from 'js-cookie';
import {cookies, countryCodes} from './constants-enumerate';

const resourceEnumerate = new ResourceEnumerate(serverEnvironment);

resourceEnumerate.before((request, response, next) => { // capture local time before
  if (request.namespace === apis.READINGIQ_MARKETING && request.cached === false) {
    ServerTime.time(apis.READINGIQ_MARKETING);
  }
  next(response);
});

resourceEnumerate.after((request, response, next) => { // capture local time after api response
  if (request.namespace === apis.READINGIQ_MARKETING && request.cached === false) {
    ServerTime.timeEnd(response.payload.reference_timestamp, apis.READINGIQ_MARKETING);
  }

  next(response);
});

resourceEnumerate.init(resourceEnumerateConfig);

// adding formatter manually because the config constant cannot change with new cookies
delete resourceEnumerate.apiRequestInstance.formatterManager.formatters[apis.READINGIQ_MARKETING];
resourceEnumerate.apiRequestInstance.addFormatter(apis.READINGIQ_MARKETING, JsonFormatter);

resourceEnumerate.get(apis.READINGIQ_MARKETING)
  .then((data) => {
    Cookies.set(cookies.COUNTRY_CODE, data.payload.country_code, cookies.CONFIG);
    Cookies.set(cookies.REGION, data.payload.region, cookies.CONFIG);
    readingIqTrackerQueue.processQueue();

    if (data.payload.country_code in countryCodes) {
      if (typeof Cookies.get(cookies.GDPR) === 'undefined' || Cookies.get(cookies.GDPR) !== 'hide') {
        Cookies.set(cookies.GDPR, 'show', cookies.CONFIG);
      }
    }
  });

export {
  resourceEnumerate
};
