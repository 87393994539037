import '../../template/main/modules/main-layout';
import '../../template/main/modules/main-header';
import '../../template/main/modules/main-footer';
import '../../modules/authstate-context';
import '../../modules/link-to';

export default (ctx, html) => html`
  <main-layout full-height>
    <main-header dom-scope="iXfE6kF4" slot="page-header">
      <h1 slot="page-title" class="page-tag">Page Not Found</h1>
    </main-header>
    <section class="page-content" slot="page-content">
      <h1 id="cdiv">404</h1>
      <p id="page-not-found">Page Not Found</p>
      <div class="button-wrapper enteractive tab-outline-blue" role="button" type="submit" @click="${(e) => ctx.goHomeClick(e)}" tabindex="0" aria-label="Return home.">
        <button dom-scope="ElljrtY2" id="home-link" class="button blue" tabindex="-1">Home</button>
      </div>
    </section>
    <main-footer dom-scope="f9Alc41u" slot="page-footer"></main-footer>
</main-layout>
`;
