import '../../../../modules/link-to';
import {pages, cookies, environment, outboundLinks, privacyPolicySections} from '../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';

export default (ctx, html) => html`
  <span id="copyright">TM &amp; &copy; ${ctx.currentYear}
    ${environment.FLUTTER_ANDROID || environment.UNITY_ANDROID || environment.IS_LINK_FROM_APP ? html`
      Age of Learning, Inc.
    ` : html`
      <link-to id="aofl-link" dom-scope="/e7E+vXP" href="https://www.ageoflearning.com/" target="_blank" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Age of Learning, Inc.</span></link-to>
    `}
    <span> | </span>
  </span>
  <span id="links" class="${ctx.isAU}">
    <link-to dom-scope="wnJdPOjx" href="${environment.FLUTTER_ANDROID || environment.UNITY_ANDROID ? pages.TANDC_LINK_FROM_APP_ANDROID : pages.TANDC}" target="_blank" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Terms &amp; Conditions</span></link-to>
    <span class="u-m-br"></span>
    <span class="u-m-hide hide-pipe"> | </span>
    ${Cookies.get(cookies.REGION) === 'CA' ? html`
      <link-to dom-scope="GC1B4qrw" class="link" href="${environment.FLUTTER_ANDROID || environment.UNITY_ANDROID ? privacyPolicySections.CALIFORNIA_PRIVACY_RIGHTS_LINK_FROM_APP_ANDROID : privacyPolicySections.CALIFORNIA_PRIVACY_RIGHTS}" target="_blank" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Privacy Policy</span></link-to>
    `: html`
      <link-to dom-scope="QmLj9zdl" href="${environment.FLUTTER_ANDROID || environment.UNITY_ANDROID ? pages.PRIVACY_POLICY_LINK_FROM_APP_ANDROID : pages.PRIVACY_POLICY}" target="_blank" cduse-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">${ctx.showAustralianPrivacyStatement ? 'Australian Privacy Statement & Privacy Policy' : 'Privacy Policy'}</span></link-to>
    `}
    <span class="hide-pipe ${ctx.isAU}"> | </span>
    ${Cookies.get(cookies.REGION) !== 'CA' || Cookies.get(cookies.COUNTRY_CODE) !== 'US' ? '' : html`
      <a dom-scope="72VABXnb" id="sell-info" class="enteractive tab-outline-blue" role="link" @click="${(e) => ctx.showCookieConsentModal(e)}" tabindex="0">Do Not Sell My Personal Information</a>
      <span class="u-m-br"></span>
      <span class="u-m-hide hide-pipe"> | </span>
    `}
    <link-to dom-scope="hrEE6/7r" href="${pages.CUSTOMER_SUPPORT}" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Customer Support</span></link-to>
    <span> | </span>
    <link-to dom-scope="tGD8n1Su" target="_blank" href="${environment.FLUTTER_ANDROID || environment.UNITY_ANDROID ? pages.ACCESSIBILITY_LINK_FROM_APP_ANDROID : outboundLinks.AOFL_ACCESSIBILITY}" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Accessibility Statement</span></link-to>
  </span>
`;
