export default {
  'baseline_version': '3029',
  'conditions': {
    '393': '2645-cit-mit-v1-test',
  },
  'qualification_order': {
    '/': ['393'],
    '/login': ['393'],
    '/reactivation': ['393'],
    '/subscription': ['393'],
    '/subscription-annual': ['393'],
    '/upgrade': ['393'],
    '/redeem': ['393'],
  },
  'versions': {
    '3029': 'routes',
    '3030': 'routes',
    '3031': '2645-cit-mit-v1-test',
  },
  'weights': {
    '393': {
      '3030': 1,
      '3031': 1,
    }
  }
};
