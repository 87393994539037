export default [
  {
    tagName: 'A',
    parents: [
      'LOGIN-FORM',
      'MAIN-HEADER',
      'MAIN-FOOTER'
    ]
  },
  {
    tagName: 'LINK-TO'
  },
  {
    tagName: 'BUTTON',
    parents: 'PAYMENT-FORM'
  },
  {
    tagName: 'BUTTON',
    parents: 'REGISTER-FORM'
  },
  {
    tagName: 'MODAL-CLOSE-BUTTON'
  },
  {
    tagName: 'BUTTON',
    parents: ['LOGIN-FORM']
  },
  {
    tagName: 'LABEL',
    parents: ['SUBSCRIPTION-FORM']
  },
  {
    tagName: 'MODAL-CLOSE-BUTTON',
    parents: ['READINGIQ-MODAL']
  },
  {
    tagName: 'BUTTON',
    parents: ['PROSPECT-REG-FORM']
  },
  {
    tagName: 'LINK-TO',
    parents: ['PROSPECT-REG-FORM']
  },
  {
    tagName: 'LOGOUT-BUTTON'
  }
];
