import styles from './template.css';
import template from './template';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {AoflElement, customElement} from '@aofl/element';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

/**
 * @summary BackButton
 * @class BackButton
 * @extends {AoflElement}
 */
@customElement('back-button')
class BackButton extends isMobileMixin(accessibilityMixin(AoflElement)) {
  /**
   * Creates an instance of BackButton.
   */
  constructor() {
    super();
    this.urlSearchParams = new URLSearchParams(window.location.search);
  }

  /**
   *
   * @private
   * @readonly
   */
  static is = 'back-button';

  /**
   *
   * @param {Event} e
   */
  goBack(e) {
    window.history.back();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default BackButton;
