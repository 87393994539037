/* eslint-disable */
export default {
  'routes': [
    {
      'resolve': () => import('./../../routes/404/index.js'),
      'rotation': 'routes',
      'path': '/404/',
      'dynamic': false,
      'title': 'Page Not Found | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': '404'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/accessibility/index.js'),
      'rotation': 'routes',
      'path': '/accessibility/',
      'dynamic': false,
      'title': 'Accessibility | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'accessibility'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/cookie-policy/index.js'),
      'rotation': 'routes',
      'path': '/cookie-policy/',
      'dynamic': false,
      'title': 'Cookie Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'cookie-policy'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/customer-support/index.js'),
      'rotation': 'routes',
      'path': '/customer-support/',
      'dynamic': false,
      'title': 'Customer Support | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'customer-support'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/download-app/index.js'),
      'rotation': 'routes',
      'path': '/download-app/',
      'dynamic': false,
      'title': 'Download App | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'download-app'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/email-unsubscribe/index.js'),
      'rotation': 'routes',
      'path': '/email-preferences/:recipient_id/',
      'dynamic': true,
      'title': 'Email Unsubscribe | ReadingIQ',
      'meta': {
        'requiresAuthentication': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'email-preferences'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/faq-classroom-rewards/index.js'),
      'rotation': 'routes',
      'path': '/cr-faqs/',
      'dynamic': false,
      'title': 'Teacher Rewards FAQs | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'cr-faqs'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/forgot-password/index.js'),
      'rotation': 'routes',
      'path': '/forgot-password/',
      'dynamic': false,
      'title': 'Forgot Password | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'forgot-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/device/index.js'),
      'rotation': 'routes',
      'path': '/device/',
      'dynamic': false,
      'title': 'Device | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'device'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/home/index.js'),
      'rotation': 'routes',
      'path': '/',
      'dynamic': false,
      'title': 'Reading IQ: 1,000+ Digital Books for Kids 2-12',
      'meta': {
        'allowedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'home'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/login/index.js'),
      'rotation': 'routes',
      'path': '/login/',
      'dynamic': false,
      'title': 'Log In | ReadingIQ',
      'meta': {
        'allowedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'login'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-20181115/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/20181115/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-20181115'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-20200101/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/20200101/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-20200101'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/privacy-policy-previous/index.js'),
      'rotation': 'routes',
      'path': '/privacy-policy/previous/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-previous'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reactivation/index.js'),
      'rotation': 'routes',
      'path': '/reactivation/',
      'dynamic': false,
      'title': 'Reactivation | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reactivation'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeem-create-account/index.js'),
      'rotation': 'routes',
      'path': '/redeem/create-account/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'create-account'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/redeemcodes/index.js'),
      'rotation': 'routes',
      'path': '/redeem/',
      'dynamic': false,
      'title': 'Redeem a Code | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'redeem'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/register-ios/index.js'),
      'rotation': 'routes',
      'path': '/register-ios/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/register-ios-reactivation/index.js'),
      'rotation': 'routes',
      'path': '/register-ios-reactivation/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios-reactivation'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/reset-password/index.js'),
      'rotation': 'routes',
      'path': '/reset-password/:resetPasswordToken/',
      'dynamic': true,
      'title': 'Reset Password | Reading IQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reset-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-abcmouse-offer-743959d/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/743959d/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-743959d'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-annual/index.js'),
      'rotation': 'routes',
      'path': '/subscription-annual/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-annual'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription/index.js'),
      'rotation': 'routes',
      'path': '/subscription/',
      'dynamic': false,
      'title': 'Become a Member! | ReadingIQ',
      'meta': {
        'allowedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-groupon-offer-04c6921/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/04c6921/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-04c6921'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-classroom-rewards-7e47b3e/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/7e47b3e/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'redirectToHomePage': true,
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7e47b3e'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-holiday-offer-79d8e34/index.js'),
      'rotation': 'routes',
      'path': '/subscription/holiday/offer/79d8e34/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1577001600,
          'endTime': 1578902399
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-holiday-offer'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-free-account/index.js'),
      'rotation': 'routes',
      'path': '/subscription/teacher/',
      'dynamic': false,
      'title': 'Become a Member! | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-free-account-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-seasonal-offer-4d28dr6/index.js'),
      'rotation': 'routes',
      'path': '/subscription/seasonal/offer/4d28dr6/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1581408000,
          'endTime': 1582185540
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-seasonal-offer-aecc093/index.js'),
      'rotation': 'routes',
      'path': '/subscription/fall/offer/aecc093/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1576017790,
          'endTime': 1576915199
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-aecc093'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/subscription-teacher-offer-1as5s7f/index.js'),
      'rotation': 'routes',
      'path': '/subscription/offer/1as5s7f/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-1as5s7f'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/',
      'dynamic': false,
      'title': 'Upgrade | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terms-and-conditions/index.js'),
      'rotation': 'routes',
      'path': '/terms-and-conditions/',
      'dynamic': false,
      'title': 'Terms and Conditions | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/terminate/index.js'),
      'rotation': 'routes',
      'path': '/terminate/cFn0vG4yW/',
      'dynamic': false,
      'title': 'Terminate | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'terminate'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade-seasonal-offer-aecc093/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/offer/aecc093/',
      'dynamic': false,
      'title': 'Upgrade | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade-groupon/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/offer/04c6921/',
      'dynamic': false,
      'title': 'Upgrade Offer | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade-offer-04c6921'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes/upgrade-teacher/index.js'),
      'rotation': 'routes',
      'path': '/upgrade/offer/1as5s7f/',
      'dynamic': false,
      'title': 'Upgrade Offer | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade-offer-1as5s7f'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    }
  ],
  '2645-cit-mit-v1-test': [
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/404/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/404/',
      'dynamic': false,
      'title': 'Page Not Found | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': '404'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/accessibility/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/accessibility/',
      'dynamic': false,
      'title': 'Accessibility | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'accessibility'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/customer-support/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/customer-support/',
      'dynamic': false,
      'title': 'Customer Support | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'customer-support'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/cookie-policy/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/cookie-policy/',
      'dynamic': false,
      'title': 'Cookie Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'cookie-policy'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/device/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/device/',
      'dynamic': false,
      'title': 'Device | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'device'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/download-app/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/download-app/',
      'dynamic': false,
      'title': 'Download App | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'download-app'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/email-unsubscribe/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/email-preferences/:recipient_id/',
      'dynamic': true,
      'title': 'Email Unsubscribe | ReadingIQ',
      'meta': {
        'requiresAuthentication': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'email-preferences'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/faq-classroom-rewards/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/cr-faqs/',
      'dynamic': false,
      'title': 'Teacher Rewards FAQs | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'cr-faqs'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/forgot-password/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/forgot-password/',
      'dynamic': false,
      'title': 'Forgot Password | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'forgot-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/home/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/',
      'dynamic': false,
      'title': 'Reading IQ: 1,000+ Digital Books for Kids 2-12',
      'meta': {
        'allowedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'home'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/privacy-policy/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/privacy-policy/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/privacy-policy-20200101/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/privacy-policy/20200101/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-20200101'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/privacy-policy-20181115/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/privacy-policy/20181115/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-20181115'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/privacy-policy-previous/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/privacy-policy/previous/',
      'dynamic': false,
      'title': 'Privacy Policy | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'privacy-policy-previous'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/reactivation/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/reactivation/',
      'dynamic': false,
      'title': 'Reactivation | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reactivation'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/redeem-create-account/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/redeem/create-account/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'create-account'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/redeemcodes/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/redeem/',
      'dynamic': false,
      'title': 'Redeem a Code | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'redeem'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/login/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/login/',
      'dynamic': false,
      'title': 'Log In | ReadingIQ',
      'meta': {
        'allowedForMembers': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'login'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/register-ios/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/register-ios/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/register-ios-reactivation/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/register-ios-reactivation/',
      'dynamic': false,
      'title': 'ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'register-ios-reactivation'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/reset-password/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/reset-password/:resetPasswordToken/',
      'dynamic': true,
      'title': 'Reset Password | Reading IQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'reset-password'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-abcmouse-offer-743959d/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/offer/743959d/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-743959d'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-annual/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription-annual/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-annual'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-classroom-rewards-7e47b3e/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/offer/7e47b3e/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'redirectToHomePage': true,
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-7e47b3e'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-free-account/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/teacher/',
      'dynamic': false,
      'title': 'Become a Member! | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-free-account-page'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-groupon-offer-04c6921/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/offer/04c6921/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-04c6921'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-holiday-offer-79d8e34/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/holiday/offer/79d8e34/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1577001600,
          'endTime': 1578902399
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-holiday-offer'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-seasonal-offer-4d28dr6/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/seasonal/offer/4d28dr6/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1581408000,
          'endTime': 1582185540
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/',
      'dynamic': false,
      'title': 'Become a Member! | ReadingIQ',
      'meta': {
        'allowedForMembers': false,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-seasonal-offer-aecc093/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/fall/offer/aecc093/',
      'dynamic': false,
      'title': 'Subscription Offer | Reading IQ',
      'meta': {
        'expirationRange': {
          'startTime': 1576017790,
          'endTime': 1576915199
        },
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-seasonal-offer-aecc093'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/subscription-teacher-offer-1as5s7f/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/subscription/offer/1as5s7f/:redeemCode/',
      'dynamic': true,
      'title': 'Subscription Offer | ReadingIQ',
      'meta': {
        'requiresValidRedeemCode': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'subscription-offer-1as5s7f'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/terminate/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/terminate/cFn0vG4yW/',
      'dynamic': false,
      'title': 'Terminate | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'terminate'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/terms-and-conditions/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/terms-and-conditions/',
      'dynamic': false,
      'title': 'Terms and Conditions | ReadingIQ',
      'meta': {
        'pageInfo': [
          {
            'name': 'page',
            'id': 'tandc'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/upgrade/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/upgrade/',
      'dynamic': false,
      'title': 'Upgrade | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/upgrade-groupon/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/upgrade/offer/04c6921/',
      'dynamic': false,
      'title': 'Upgrade Offer | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade-offer-04c6921'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/upgrade-teacher/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/upgrade/offer/1as5s7f/',
      'dynamic': false,
      'title': 'Upgrade Offer | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade-offer-1as5s7f'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    },
    {
      'resolve': () => import('./../../routes-2645-cit-mit-v1-test/upgrade-seasonal-offer-aecc093/index.js'),
      'rotation': '2645-cit-mit-v1-test',
      'path': '/upgrade/offer/aecc093/',
      'dynamic': false,
      'title': 'Upgrade | ReadingIQ',
      'meta': {
        'requiresAuthentication': true,
        'pageInfo': [
          {
            'name': 'page',
            'id': 'upgrade'
          }
        ]
      },
      'locale': 'en-US',
      'template': 'main'
    }
  ]
};