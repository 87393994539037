import {readingIqTrackerQueue} from './readingiq-tracker-queue';

const getPageLoadOrientation = (page) => {
  readingIqTrackerQueue.enqueueEvent({
    event_type: 'AOFL.READINGIQ_MARKETING.V1.marketing-page-load-orientation',
    payload: {
      orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
      url: location.origin + page
    }
  });
};

const detectOrientationChange = () => {
  try {
    const orientation = window.matchMedia('(orientation: landscape)');
    orientation.addEventListener('change', (mq) => {
      const currentOrientation = mq.matches ? 'landscape' : 'portrait';
      readingIqTrackerQueue.enqueueEvent({
        event_type: 'AOFL.READINGIQ_MARKETING.V1.marketing-page-orientation-change',
        payload: {
          url: location.href,
          orientation: currentOrientation
        }
      });
    });
  } catch (e) {}
};

export {
  getPageLoadOrientation,
  detectOrientationChange
};
