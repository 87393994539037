import {sdoNamespaces, pages, environment, regStepsEnumerate, cookies} from './constants-enumerate';
import {storeInstance} from '@aofl/store/dist/legacy';
import {routerInstance} from '@aofl/router';
import {ApiService} from './api-service.js';
import {AppCallService} from './app-call-service';
import Cookies from 'js-cookie';
import {isMobile} from '@aoflmkt/is-mobile';

/**
 *
 * @class RegistrationPathService
 */
class RegistrationPathService {
  /**
   *
   * @static
   * @return {Object}
   */
  static getSessionData() {
    const state = storeInstance.getState();
    return {
      isLoggedIn: state[sdoNamespaces.SESSION].isLoggedIn,
      isActive: state[sdoNamespaces.SESSION].userInfo.isActive,
      nextStep: state[sdoNamespaces.SESSION].userInfo.nextStep,
      userUuid: state[sdoNamespaces.SESSION].userInfo.userUuid,
      userAccountType: state[sdoNamespaces.SESSION].userInfo.userAccountType
    };
  }

  /**
   *
   * @static
   */
  static async validateLogin() {
    try {
      const resp = await ApiService.validateLogin();
      if (resp.payload.token_valid) { // eslint-disable-line
        storeInstance.commit({
          namespace: sdoNamespaces.SESSION,
          mutationId: 'setLoggedIn'
        });
      } else {
        storeInstance.commit({
          namespace: sdoNamespaces.SESSION,
          mutationId: 'setLoggedOut'
        });
      }

      return resp.payload;
    } catch (e) {
    }
  }

  /**
   *
   * @static
   * @return {Function}
   */
  static async next() {
    const validateResp = await this.validateLogin();
    const token = Cookies.get(cookies.TOKEN);
    const {nextStep} = this.getSessionData();

    if (environment.IN_APP) {
      if (nextStep === regStepsEnumerate.COMPLETE) {
        return AppCallService.routeToApp(token);
      }
      if (nextStep === regStepsEnumerate.UPGRADE && isMobile.apple.device) {
        return AppCallService.routeToApp(token);
      }
      routerInstance.navigate(pages[nextStep], {});
    } else {
      if (isMobile.any &&
        nextStep === regStepsEnumerate.COMPLETE &&
        validateResp.role.indexOf('parent') > -1) {
        window.location = pages.DOWNLOAD_APP;
        return;
      } else if (nextStep === regStepsEnumerate.COMPLETE) {
        window.location = pages[nextStep];
        return;
      }
      routerInstance.navigate(pages[nextStep], {});
    }
  }
}


export {
  RegistrationPathService
};
