import './session-sdo';
import '../template/main/modules/main-layout/sdo';
import {environmentTypeEnumerate} from '@aofl/server-environment';
import {environment as serverEnvironment} from './server-environment';
import {routerInstance} from '@aofl/router';
import {storeInstance} from '@aofl/store/dist/legacy';
import {RotationsService} from './rotations-service';
import {modalsConfig} from './__config/modals';
import {RegistrationPathService} from './registration-path-service';
import {ApiService} from './api-service.js';
import {ModalService} from '../template/main/modules/modal-service';
import {readingIqTrackerQueue} from './readingiq-tracker-queue';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {vanityUrls} from './__config/vanity-urls';
import {getPageInfo} from './page-load-utils';
import {CampaignService} from './campaign-service';
import {PixelService} from './pixel-service';
import {UserInfoService} from './user-info-service';
import Cookies from 'js-cookie';
import {sdoNamespaces, outboundLinks, pages, cookies, environment, cacheNamespaces, queryParamKeys, redirectCountryCodes, pixelEvents} from './constants-enumerate';
import {detectOrientationChange, getPageLoadOrientation} from './device-orientation';

ModalService.addModals(modalsConfig);

if (serverEnvironment === environmentTypeEnumerate.DEV) {
  window.storeInstance = storeInstance;
}

detectOrientationChange();

/**
 * Intializing rotation service.
 */
const ready = RotationsService.getRoutes()
  .then((routes) => {
    // ------------------------------------------------------------------------>
    routerInstance.init(routes);
    // ------------------------------------------------------------------------>
    /**
     *
     * Updates store with server session values.
     */
    routerInstance.before(async (request, response, next) => {
      try {
        const validateLoginResp = await ApiService.validateLogin();
        UserInfoService.setUserInfo(validateLoginResp.payload);
        if (validateLoginResp.payload.token_valid) { // eslint-disable-line
          storeInstance.commit({
            namespace: sdoNamespaces.SESSION,
            mutationId: 'setLoggedIn'
          });
        } else {
          storeInstance.commit({
            namespace: sdoNamespaces.SESSION,
            mutationId: 'setLoggedOut'
          });
        }
      } catch (e) {
      } finally {
        next(response);
      }
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirect to login page if user is from Papua New Guinea (PNG) and requesting the home page.
     */
    routerInstance.beforeEach((request, response, next) => {
      const countryCode = Cookies.get(cookies.COUNTRY_CODE);
      const nextRoute = response.to.split('?')[0];
      if (countryCode === redirectCountryCodes.PNG && nextRoute === pages.HOME) {
        response.to = pages.LOGIN;
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Check to see if offer is within the expiration range.
     */
    routerInstance.beforeEach((request, response, next) => {
      if (response?.matchedRoute?.meta?.expirationRange) {
        const currentTime = Math.floor(Date.now() / 1000);
        const expirationRange = response.matchedRoute.meta.expirationRange;
        if (currentTime < expirationRange.startTime || expirationRange.endTime < currentTime) {
          routerInstance.navigate(pages.CDIV, {});
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirect to login page if user is using an Amazon app.
     */
    routerInstance.beforeEach((request, response, next) => {
      if ((environment.FLUTTER_AMAZON || environment.UNITY_AMAZON ) && response?.matchedRoute?.path === pages.HOME) {
        response.to = pages.LOGIN;
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Manually sets countryCode cookie via /?country=CODE
     */
    routerInstance.beforeEach((request, response, next) => {
      if (response.to.includes('country')) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const countryCode = urlSearchParams.get(queryParamKeys.COUNTRY_CODE);
        if (countryCode) {
          Cookies.set(cookies.COUNTRY_CODE, countryCode, cookies.CONFIG);
          window.location = '/';
        }
      }
      next(response);
    });
    /**
     *
     * Redirects the user to the corresponding PP or T&C page based on the user's location.
     */
    routerInstance.beforeEach((request, response, next) => {
      let type = '';
      const countryCode = Cookies.get(cookies.COUNTRY_CODE);

      if (response?.to?.includes('/privacy-policy')) {
        type = 'PRIVACY_POLICY';
      } else if (response?.to?.includes('/terms-and-conditions')) {
        type = 'TANDC';
      }

      if (type) {
        if (countryCode === 'US') {
          window.location = outboundLinks[type];
          return;
        }

        window.location = outboundLinks.PRIVACY_HUB;
        return;
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to the appropriate affiliate url if the requested url is a valid vanity url.
     * Also used for redirecting to other landing pages for expired/terminated subscription offers.
     */
    routerInstance.beforeEach((request, response, next) => {
      const url = response?.to?.toLowerCase();
      if (vanityUrls[url]) {
        window.location = `${vanityUrls[url]}`;
        return;
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to external aofl devices url when requesting /devices
     */
    routerInstance.beforeEach((request, response, next) => {
      if ((window.location.pathname === pages.DEVICE) || (window.location.pathname.slice(0, -1) === pages.DEVICE)) {
        window.location = 'https://www.ageoflearning.com/devices/aofl_devices_en.pdf';
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to /login if the user isnt logged in when requesting a protected route, also
     * redirects to download app if conditions are met.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      const state = storeInstance.getState();
      const isLoggedIn = state[sdoNamespaces.SESSION].isLoggedIn;

      if (isLoggedIn && response?.matchedRoute?.meta?.allowedForMembers === false) {
        await RegistrationPathService.next();
        next(response);
      } else if (!isLoggedIn && response?.matchedRoute?.meta?.requiresAuthentication) {
        response.to = pages.LOGIN + `?to=${encodeURIComponent(request.to)}`;
        response.popped = false;
      }

      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Redirects the user to home if the requested path is email preferences,
     * if the user does not have an email_unsubscribe cookie and the recepient id is anything but '0'.
     */
    routerInstance.beforeEach((request, response, next) => {
      if (response?.matchedRoute?.path + '/' === pages.EMAIL_PREFERENCES) {
        const email = Boolean(Cookies.get(cookies.EMAIL_UNSUBSCRIBE));
        const recepientId = routerInstance.currentRoute.matchedRoute?.props?.recepient_id === '0'; // eslint-disable-line
        if (!email && !recepientId) {
          response.to = pages.HOME;
          response.popped = false;
        }
      }
      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Redirects the user to the 404 page if the requested route requires a redeem code and the code is invalid.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response?.matchedRoute?.meta?.redirectToHomePage) {
        response.to = pages.HOME;
        response.popped = false;
      } else if (response?.matchedRoute?.meta?.requiresValidRedeemCode) {
        try {
          let redeemCode = response.matchedRoute.props.redeemCode;
          const queryStringIndex = redeemCode.indexOf(`?${queryParamKeys.SOURCE_TAG}`);
          if (queryStringIndex !== -1) {
            redeemCode = redeemCode.slice(0, queryStringIndex);
          }
          await ApiService.validateRedeemCode(redeemCode);
        } catch (e) {
          response.to = pages.SUBSCRIPTION;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Call logout to destroy the server session, clear the browser's
     * cookies, localStorage, sessionStorage, wipe session sdo, and
     * force refresh user to home.
     *
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response.to === '/clear') {
        const state = storeInstance.getState();
        const isLoggedIn = state[sdoNamespaces.SESSION].isLoggedIn;
        if (isLoggedIn) {
          await ApiService.logout();
        }

        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, cookies.CONFIG);
        });

        localStorage.clear();
        sessionStorage.clear();

        window.location = '/';
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Sets the 'cje' cookie for the CJ from the cjevent query param.
     */
    routerInstance.beforeEach((request, response, next) => {
      if (response.to.includes(queryParamKeys.CJ_EVENT)) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const cjEvent = urlSearchParams.get(queryParamKeys.CJ_EVENT);
        if (cjEvent) {
          Cookies.set(cookies.CJ_COOKIE, cjEvent, cookies.CJ_CONFIG);
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires landing pixel events
     */
    routerInstance.after(async (request, response, next) => {
      if (!environment.IN_APP) {
        await PixelService.fireGlobalPixel(pixelEvents.LANDING);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const landingPixel = urlSearchParams.get(queryParamKeys.LANDING_PIXEL);

        if (landingPixel) {
          await PixelService.fireCampaignPixel(pixelEvents.LANDING);
        }
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires the campaign attribution event if the URL contains the 'src_tag'
     * query string parameter
     */
    routerInstance.after((request, response, next) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const sourceTag = urlSearchParams.get(queryParamKeys.SOURCE_TAG);

      if (sourceTag) {
        CampaignService.attribute(sourceTag);
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires page load events
     */
    routerInstance.after((request, response, next) => {
      const urlParamObj = {};
      const urlSearchParams = new URLSearchParams(window.location.search);

      const urlParamsArray = urlSearchParams.toString().split('&');
      urlParamsArray.forEach((param) => {
        const [key, val] = param.split('=');
        urlParamObj[key] = val;
      });

      const pageEvent = {
        event_type: 'AOFL.READINGIQ_MARKETING.V1.marketing-page-load',
        payload: {
          url: location.origin + response.to,
          referrer: request.from.replace(/^\//, location.origin + '/'),
          params_query_json: JSON.stringify(urlParamObj) /* eslint-disable-line */
        }
      };

      pageEvent.payload.page = response?.matchedRoute?.meta?.pageInfo || [
        {
          name: 'page',
          id: '404'
        }
      ];

      pageEvent.payload.page_info = getPageInfo(pageEvent.payload.page); /* eslint-disable-line */

      readingIqTrackerQueue.enqueueEvent(pageEvent);
      getPageLoadOrientation(response.to);
      PixelService.trackPageLoad(response.to);
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires a rotation assignment event, if needed.
     */
    routerInstance.after((request, response, next) => {
      const currentRotationVersion = response?.matchedRoute?.rotationInfo?.version;
      const rotationData = new CacheManager(cacheNamespaces.ROTATION_VERSION, cacheTypeEnumerate.LOCAL, 7776000000);
      const storeRotationVersion = rotationData.getItem(cacheNamespaces.ROTATION_VERSION);

      if (storeRotationVersion === null || storeRotationVersion !== currentRotationVersion) {
        rotationData.setItem(cacheNamespaces.ROTATION_VERSION, currentRotationVersion);
        const rotationAssignmentEvent = {
          event_type: 'AOFL.READINGIQ_MARKETING.V1.rotation-assignment',
          payload: {
            rotation_version_id: currentRotationVersion
          }
        };

        readingIqTrackerQueue.enqueueEvent(rotationAssignmentEvent);
      }
      next(response);
    });
    /**
     *
     * Close all modals and dropdown menus
     */
    routerInstance.after((request, response, next) => {
      if (typeof storeInstance.namespaces[sdoNamespaces.MAIN_LAYOUT] !== 'undefined') {
        ModalService.hide();
        storeInstance.commit({
          namespace: sdoNamespaces.MAIN_LAYOUT,
          mutationId: 'setContentOverlay',
          payload: false
        }, {
          namespace: sdoNamespaces.MAIN_LAYOUT,
          mutationId: 'setFooterOverlay',
          payload: false
        });
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    routerInstance.navigate(location.href.replace(location.origin, '').replace('index.html', ''), {forceReload: true, replaceState: true} );
    // ------------------------------------------------------------------------>
  });

export {
  ready,
  routerInstance
};
