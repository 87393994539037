const modalsConfig = {
  loaderIcon: {
    resolve: () => import('../../template/main/modules/loader-icon')
  },
  cookieConsentModal: {
    resolve: () => import('../../template/main/modules/cookie-consent-modal')
  },
  captchaModal: {
    resolve: () => {
      return `
      <div id="captcha-modal">
        <div id="captcha-modal-header" class="panel-header blue">Hello!</div>
        <div id="captcha-content" class="panel-content">
          <p id="verify">Please verify you are a human:</p>
          <div id="px-captcha"></div>
        </div>
      </div>
      `;
    }
  },
};

export {
  modalsConfig
};

