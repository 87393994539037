import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {cookies} from '../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';
import {ModalService} from '../modal-service';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

/**
 * @summary MainFooter
 * @extends {AoflElement}
 */
@customElement('main-footer')
class MainFooter extends isMobileMixin(clickTrackerMixin(accessibilityMixin(AoflElement))) {
  /**
   * Creates an instance of MainFooter.
   */
  constructor() {
    super();
    this.currentYear = (new Date()).getFullYear();

    this.showAustralianPrivacyStatement = Cookies.get(cookies.COUNTRY_CODE) === 'AU';
    this.isAU = Cookies.get(cookies.COUNTRY_CODE) === 'AU' ? 'is-au' : '';
  }

  /**
   * @readonly
   */
  static is = 'main-footer';

  /**
   * @param {Event} e
   */
  showCookieConsentModal(e) {
    ModalService.show('cookieConsentModal');
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainFooter;
