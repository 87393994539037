import {isNotQualified} from '../../../../../modules/is-webp-qualified';

const imageConfig = {
  riqLogo: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/RIQ-logo-header-mb-v1-1x.png') : require('../images/RIQ-logo-header-mb-v1-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/RIQ-logo-header-mb-v1-2x.png') : require('../images/RIQ-logo-header-mb-v1-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/RIQ-logo-header-mb-v1-3x.png') : require('../images/RIQ-logo-header-mb-v1-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/RIQ-logo-header-dt-v1-1x.png') : require('../images/RIQ-logo-header-dt-v1-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/RIQ-logo-header-dt-v1-2x.png') : require('../images/RIQ-logo-header-dt-v1-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/RIQ-logo-header-dt-v1-3x.png') : require('../images/RIQ-logo-header-dt-v1-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/RIQ-logo-header-dt-v1-4x.png') : require('../images/RIQ-logo-header-dt-v1-4x.webp')}`}
  ]
};

export {
  imageConfig
};
