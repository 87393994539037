import {environment as serverEnvironment} from './server-environment';
import './route-view';
import './init-router-service';
import {environmentTypeEnumerate} from '@aofl/server-environment';
import './resource-enumerate';
import {isMobile} from '@aoflmkt/is-mobile';
import {environment} from './constants-enumerate';

/* istanbul ignore next */
if (isMobile.any) {
  document.documentElement.classList.add('page-mobile');
}

// Disable scroll jumping when navigating between pages
if (typeof history === 'object' && 'scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

/* istanbul ignore next */
if (serverEnvironment !== environmentTypeEnumerate.DEV &&
typeof aofljsConfig.__prerender__ === 'undefined') {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register(environment.PUBLIC_PATH + 'sw.js', { // eslint-disable-line
      scope: environment.PUBLIC_PATH
    });
  }
}

