/**
 * @param {Object} page
 * @return {Object}
 */
const getPageInfo = (page) => {
  let pageId = '';
  const views = [];

  for (const pageObj of page) {
    if (pageObj.name === 'page') {
      pageId = pageObj.id;
    } else if (pageObj.name === 'view') {
      views.push({name: pageObj.id});
    }
  }

  return {
    page_url: '/' + pageId, /* eslint-disable-line */
    page_name: pageId, /* eslint-disable-line */
    view_info: views /* eslint-disable-line */
  };
};

/**
 * Resolves the correct page to be served determined by the imported condition file above.
 * Will return the 'default' page if no conditons are met.
 *
 * @param {Object} pageConditions
 * @return {Object}
 */
const resolvePage = (pageConditions) => {
  let page = {};
  for (page in pageConditions) {
    if (!Object.prototype.hasOwnProperty.call(pageConditions, page)) continue;
    if (pageConditions[page]?.condition()) {
      return pageConditions[page];
    }
  }
};

export {
  getPageInfo,
  resolvePage
};

