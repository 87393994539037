import '@aofl/picture';
import '../../../../modules/link-to';
import '../back-button';
import {pages} from '../../../../modules/constants-enumerate';
import {imageConfig} from './__config/images';

export default (ctx, html) => html`
  <section id="top-header">
    <div id="skip-navigation">
      <slot name="skip-navigation"></slot>
    </div>
    ${ctx.preventRedirect ? html`
      <aofl-picture id="reading-iq-logo">
        ${imageConfig.riqLogo.map((image) => html`
          <aofl-source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
        `)}
        <aofl-img src='${imageConfig.riqLogo[6].src}' width="300" height="57" alt="ReadingIQ Logo">
      </aofl-picture>
      ` : html`
      <link-to id="reading-iq-logo-link" dom-scope="u5e27d4L" href="${pages.HOME}" role="navigation" aria-label="ReadingIQ" tab-outline-blue>
        <aofl-picture id="reading-iq-logo">
          ${imageConfig.riqLogo.map((image) => html`
            <aofl-source media="(max-width: ${image.maxWidth}px" srcset="${image.src}">
          `)}
          <aofl-img src='${imageConfig.riqLogo[6].src}' width="300" height="57" alt="ReadingIQ Logo">
        </aofl-picture>
      </link-to>
    `}

    <div id="header-links-container">
      <slot name="top-right"></slot>
    </div>
  </section>

  <section id="bottom-header">
    <div id="bottom-header-content">
      ${ctx.displayBackButton ? html`
        <div class="back-button-container">
          <back-button></back-button>
        </div>
      ` : ''}

      <slot name="page-title"></slot>
    </div>
  </section>
`;
