import {ApiRequest} from '@aofl/api-request';
import {apiFormatters, apis, cacheNamespaces, environment, usernameTypes} from './constants-enumerate';
import JsonFormatter from './__config/json-formatter';
import {resourceEnumerate} from './resource-enumerate';

const apiRequest = new ApiRequest();
apiRequest.addFormatter(apiFormatters.READINGIQ_JSON, JsonFormatter);

/**
 *
 * Please keep all api endpoints in alphabetical order, with the exception of request()
 */
class ApiService {
  /**
   *
   * @param {*} apiEndpointKey
   * @param {*} payload
   * @param {*} format
   * @param {*} fromCache
   * @param {*} namespace
   */
  static async request(apiEndpointKey, payload, format = apiFormatters.READINGIQ_JSON,
  fromCache = true, namespace = 'default') {
    const resources = await resourceEnumerate.get(apis.READINGIQ_MARKETING);
    const url = resources.payload.api_domains.api_root_url + resources.payload.api_endpoints[apiEndpointKey];
    const response = await apiRequest.request(url, payload, format, fromCache, namespace);
    return response;
  }

  /**
   * Login to be called for Sams Club flow.
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static accountServiceLogin(payload) { // eslint-disable-line
    const request = {
      args: [payload] // eslint-disable-line
    };

    return this.request('accountService_login', request, apiFormatters.READINGIQ_JSON, false);
  }


  /**
   * Required in order to pass the link_token from Sams Club.
   *
   * @param {String} token (the login response token)
   * @param {String} link_token (the link token from sams club, only for bundles)
   * @return {Promise}
   */
  static acceptLinkRequest(token, link_token) { // eslint-disable-line
    const request = {
      args: [{token, link_token, platform: environment.PLATFORM}] // eslint-disable-line
    };

    return this.request('accountService_accept_link_request', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {Object} _payload.receipt_data
   * @param {String} _payload.product_hash
   * @return {Promise}
   */
  static checkItunesUserActive(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('prospect_itunesvalidatereceipt', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static createTicket(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('customersupport_createticket', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static doesUserExistInOtherProducts(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('prospect_is_user_of_any_other_products', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static emailSubscribe(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('prospect_registerusername', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static eventLog(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('event_log', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static forgotPassword(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('user_requestcredentialrecovery', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {String} _payload.billingInfo
   * @param {String} _payload.productHash
   * @param {String} _payload.platform
   * @return {Promise}
   */
  static freeUserUpgrade(_payload) {
    const request = {
      args: [
        {
          product_hash: _payload.productHash, // eslint-disable-line
          billing_info: _payload.billingInfo, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('freeuser_upgrade', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} event
   * @return {Promise}
   */
  static getCampaignPixel(event) {
    const request = {
      args: [
        {
          event
        }
      ]
    };

    return this.request('campaign_getpixel', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} productHash
   * @return {Promise}
   */
  static getProductByHash(productHash) {
    const request = {
      args: [
        {
          product_hash: productHash // eslint-disable-line
        }
      ]
    };

    return this.request('product_getuuidbyhash', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {Object} _payload.accountInfo
   * @param {String} _payload.appStore
   * @param {String} _payload.productHash
   * @param {String} _payload.receiptData
   * @return {Promise}
   */
  static itunesSubscribe(_payload) {
    const request = {
      args: [
        {
          account_info: _payload.accountInfo, // eslint-disable-line
          app_store: _payload.appStore, // eslint-disable-line
          product_hash: _payload.productHash, // eslint-disable-line
          receipt_data: _payload.receiptData, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('prospect_itunessubscribe', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {String} _payload.productHash
   * @param {String} _payload.paymentType
   * @param {Object} _payload.billingInfo
   * @param {String} _payload.accessCode
   * @return {Promise}
   */
  static itunesReactivate(_payload) {
    const request = {
      args: [
        {
          product_hash: _payload.productHash, // eslint-disable-line
          payment_type: _payload.paymentType, // eslint-disable-line
          billing_info: _payload.billingInfo, // eslint-disable-line
          access_code: _payload.accessCode, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('subscriber_itunesresubscribe', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {Object} _payload.accountInfo
   * @param {String} _payload.appStore
   * @param {String} _payload.receiptData
   * @return {Promise}
   */
  static itunesRestoreTransaction(_payload) {
    const request = {
      args: [
        {
          account_info: _payload.accountInfo, // eslint-disable-line
          app_store: _payload.appStore, // eslint-disable-line
          receipt_data: _payload.receiptData, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('prospect_itunesrestoretransaction', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static login(_payload) {
    const request = {
      args: [_payload]
    };
    apiRequest.getCacheManager(cacheNamespaces.API_AUTH).clear();
    return this.request('user_login', request, apiFormatters.READINGIQ_JSON, false, cacheNamespaces.API_AUTH);
  }

  /**
   * @static
   * @return {Promise}
   */
  static logout() {
    apiRequest.getCacheManager(cacheNamespaces.API_AUTH).clear();
    return this.request('user_logout', {}, apiFormatters.READINGIQ_JSON, false, cacheNamespaces.API_AUTH);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {String} _payload.productHash
   * @param {String} _payload.paymentType
   * @param {Object} _payload.billingInfo
   * @param {String} _payload.accessCode
   * @return {Promise}
   */
  static reactivate(_payload) {
    const request = {
      args: [
        {
          product_hash: _payload.productHash, // eslint-disable-line
          payment_type: _payload.paymentType, // eslint-disable-line
          billing_info: _payload.billingInfo, // eslint-disable-line
          access_code: _payload.accessCode, // eslint-disable-line
          code_redeem: _payload.codeRedeem, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('subscriber_resubscribe', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload payload to send to API
   */
  static redeemCreateAccount(_payload) {
    const request = {
      args: [
        {
          code: _payload.code,
          redeem_action: _payload.redeemAction, // eslint-disable-line
          account_data: { // eslint-disable-line
            redeem_code: _payload.code, // eslint-disable-line
            username: _payload.accountData.email,
            username_type: _payload.accountData.username_type, // eslint-disable-line
            password: _payload.accountData.password,
            platform: environment.PLATFORM
          },
          redeem_response: _payload.schoolInfo
        }
      ]
    };

    return this.request('code_redeem', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} completedStep
   * @param {String} userType
   * @return {Promise}
   */
  static regPath(completedStep = '', userType = 'subscriber') {
    const request = {
      args: [
        {
          completed_step: completedStep, // eslint-disable-line
          subscriber_type: userType // eslint-disable-line
        }
      ]
    };

    return this.request('registration_path_get_next_step', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static resetPassword(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('user_resetpassword', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @return {Promise}
   */
  static resubscribeProspectGetInfo() {
    return this.request('subscriber_getinfoforresubscribe', '', apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {Object} _payload.accountInfo
   * @param {String} _payload.productHash
   * @param {String} _payload.paymentType
   * @param {Object} _payload.billingInfo
   * @param {String} _payload.accessCode
   * @return {Promise}
   */
  static subscribe(_payload) {
    const navigatorCopy = {};
    for (const key in window.navigator) { // eslint-disable-line
      navigatorCopy[key] = window.navigator[key]; // eslint-disable-line
    }

    const optionalData = {
      window: navigatorCopy
    };

    const request = {
      args: [
        {
          account_info: _payload.accountInfo, // eslint-disable-line
          product_hash: _payload.productHash, // eslint-disable-line
          payment_type: _payload.paymentType, // eslint-disable-line
          billing_info: _payload.billingInfo, // eslint-disable-line
          access_code: _payload.accessCode, // eslint-disable-line
          platform: environment.PLATFORM,
          recaptcha_token: _payload.recaptchaToken, // eslint-disable-line
          domain: _payload.domain,
          optional_data: optionalData
        }
      ]
    };
    return this.request('prospect_subscribe', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @param {Object} _payload.accountInfo
   * @param {String} _payload.productHash
   * @param {String} _payload.paymentType
   * @param {Object} _payload.billingInfo
   * @param {String} _payload.accessCode
   * @param {String} _payload.redeemCode
   * @param {String} _payload.regPathKey
   * @return {Promise}
   */
  static subscribeCodeRedeem(_payload) {
    const request = {
      args: [
        {
          account_info: _payload.accountInfo, // eslint-disable-line
          product_hash: _payload.productHash, // eslint-disable-line
          payment_type: _payload.paymentType, // eslint-disable-line
          billing_info: _payload.billingInfo, // eslint-disable-line
          access_code: _payload.accessCode, // eslint-disable-line
          recaptcha_token: _payload.recaptchaToken, // eslint-disable-line
          domain: _payload.domain,
          code_redeem: _payload.codeRedeem, // eslint-disable-line
          platform: environment.PLATFORM,
          regpath_key: _payload.regPathKey // eslint-disable-line
        }
      ]
    };

    return this.request('prospect_subscribe', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} userUuid
   * @return {Promise}
   */
  static terminate(userUuid) {
    const request = {
      args: [
        {
          user_uuid: userUuid // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_terminate', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {Object} _payload
   * @return {Promise}
   */
  static unsubscribe(_payload) {
    const request = {
      args: [_payload]
    };

    return this.request('emailrecipient_updateglobalstatus', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {*} productHash
   * @return {Promise}
   */
  static upgrade(productHash) {
    const request = {
      args: [
        {
          product_hash: productHash // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_upgrade', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @return {Promise}
   */
  static validateLogin() {
    return this.request('user_validatelogin', {}, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} code redeem code to validate
   * @return {Promise}
   */
  static validateRedeemCode(code) {
    const request = {
      args: [
        {
          code
        }
      ]
    };

    return this.request('code_validate', request, apiFormatters.READINGIQ_JSON, false);
  }

  /**
   * @static
   * @param {String} email
   * @return {Promise}
   */
  static emailIsActiveUser(email) {
    const request = {
      args: [
        {
          username: email,
          username_type: usernameTypes.EMAIL_ADDRESS
        }
      ]
    };

    return this.request('user_isactive', request, apiFormatters.READINGIQ_JSON, false);
  }
}

export {
  apiRequest,
  ApiService
};
