import '../../../../modules/link-to';
import '@aofl/picture';
import {pages} from '../../../../modules/constants-enumerate';

export default (ctx, html) => html`
    <div>
      <a dom-scope="ldhu4Dn0" href="#" @click=${(e) => ctx.hideBanner(e)} class="enteractive tab-outline-blue" tabindex="0">
        <aofl-img id="close-button" src="${require('../../images/close-icon.png')}" width="53" height="52"></aofl-img>
      </a>
      <span>We use cookies to deliver the best possible experience on our website and to analyse usage.</br>Our <link-to class="underline" dom-scope="ZElvj+hn" href="${pages.PRIVACY_POLICY}" target="_blank" use-parent-gate tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Cookie Policy</span></link-to> provides further information on our cookie uses and how to amend your cookie settings.</span>
      <span>If you continue to use our website we will assume you are ok with our use of cookies.</span>
    </div>
`;
