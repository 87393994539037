import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {environment} from '../../../../modules/constants-enumerate';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

/**
 * @summary MainHeader
 * @extends {AoflElement}
 */
@customElement('main-header')
class MainHeader extends isMobileMixin(clickTrackerMixin(accessibilityMixin(AoflElement))) {
  /**
   * Creates an instance of MainHeader.
   */
  constructor() {
    super();
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.displayBackButton = ((environment.FLUTTER_IOS || environment.UNITY_IOS) &&
      !this.hideBackButton);
  }

  /**
   * @readonly
   */
  static is = 'main-header';

  /**
   *
   * @return {Object}
   */
  @property({type: Boolean, attribute: 'prevent-redirect'})
  preventRedirect = false;
  @property({type: Boolean, attribute: 'hide-back-button'})
  hideBackButton = false;
  @property({type: Boolean, attr: true})
  displayBackButton = false;

  /**
   *
   */
  disconnectedCallback() {
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainHeader;
