import {template} from './template';
import styles from './template.css';
import {AoflElement, customElement} from '@aofl/element';
import {html} from 'lit-html';
import {routerInstance, ready as routerInstanceReady} from '../init-router-service';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import CDIV from '../cdiv-element';

/**
 *
 * @class RouteView
 * @extends {AoflElement}
 */
@customElement('route-view')
class RouteView extends isMobileMixin(AoflElement) {
  /**
   *
   * Creates an instance of RouteView.
   */
  constructor() {
    super();
    this.template = template;
    this.renderedHtmlClasses = [];

    routerInstanceReady.then(() => {
      routerInstance.after(async (request, response, next) => {
        try {
          if (response.matchedRoute === null) {
            throw new Error('Route Not Found');
          }

          const component = await Promise.resolve((await response.matchedRoute.resolve()).default);
          this.updateTemplate(response.matchedRoute.title, component.is, response.matchedRoute.meta.customHTMLClasses);
        } catch (err) {
          // Render 404 component
          const component = await CDIV;
          this.updateTemplate('404 - Not Found', component.is);
        } finally {
          next(response);
          await this.updateComplete;
          this.setLoaded();
        }
      });
    });
  }

  /**
   *
   * @readonly
   */
  static is = 'route-view';

  /**
     *
     * @private
     * @param {String} title
     * @param {String} tag
     * @param {Array} customHTMLClasses
     */
  updateTemplate(title, tag, customHTMLClasses = []) {
    document.title = title;
    document.documentElement.id = tag;
    this.updateHtmlClasses(customHTMLClasses);
    const resolvedTemplate = `<${tag} id="page-component"></${tag}>`;
    this.template = () => html([resolvedTemplate]);
    this.requestUpdate();
  }
  /**
   * *
   */
  setLoaded() {
    window.scrollTo(0, 0);
    setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 2000);
    document.documentElement.classList.add('lazy-load');
  }

  /**
   *
   * @param {Array} htmlClasses
   */
  updateHtmlClasses(htmlClasses = []) {
    if (this.renderedHtmlClasses.length > 0) {
      document.documentElement.classList.remove(...this.renderedHtmlClasses);
    }

    if (htmlClasses.length > 0) {
      document.documentElement.classList.add(...htmlClasses);
    }

    this.renderedHtmlClasses = htmlClasses;
  }

  /**
   *
   * @param {Array} args
   */
  connectedCallback(...args) {
    super.connectedCallback(...args);

    /* istanbul ignore next */
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }

    /* istanbul ignore else */
    if (typeof aofljsConfig.__prerender__ === 'undefined') {
      this.classList.add('loaded');
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(this.template, [styles]);
  }
}

export default RouteView;
