import Cookies from 'js-cookie';
import {cookies, queryParamKeys} from './constants-enumerate';
import {uuid} from '@aofl/uuid';

const getElogEmail = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const elogEmail = urlSearchParams.get(queryParamKeys.EMAIL);
  if (elogEmail) {
    Cookies.set(cookies.ELOG, elogEmail, cookies.CONFIG);
  }
};

const getAppVersion = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const appVersion = urlSearchParams.get(queryParamKeys.APP_VERSION);
  if (appVersion) { // If falsey, this cookie is already being set by the unity/flutter client
    Cookies.remove(cookies.APP_VERSION, {secure: cookies.CONFIG.secure});
    Cookies.set(cookies.APP_VERSION, appVersion, cookies.CONFIG);
  }
};

const getAppSessionId = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const appSessionId = urlSearchParams.get(queryParamKeys.APP_SESSION_ID);
  if (appSessionId) { // If falsey, this cookie is already being set by the unity/flutter client
    Cookies.remove(cookies.APP_SESSION_ID, {secure: cookies.CONFIG.secure});
    Cookies.set(cookies.APP_SESSION_ID, appSessionId, cookies.CONFIG);
  }
};

const getToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get(queryParamKeys.TOKEN);
  if (token) { // If falsey, this cookie is already being set by the unity/flutter client
    Cookies.remove(cookies.TOKEN, {secure: cookies.CONFIG.secure});
    Cookies.set(cookies.TOKEN, token, cookies.CONFIG);
  }
};

const getPlatformName = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const platformName = urlSearchParams.get(queryParamKeys.PLATFORM_NAME);
  if (platformName) {
    Cookies.set(cookies.PLATFORM_NAME, platformName, cookies.CONFIG);
  }
};

const getLinkFromApp = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const linkFromApp = urlSearchParams.get(queryParamKeys.LINK_FROM_APP);
  if (linkFromApp && linkFromApp === 'android') {
    Cookies.remove(cookies.LINK_FROM_APP, {
      secure: cookies.CONFIG.secure
    });
    Cookies.set(cookies.LINK_FROM_APP, linkFromApp, cookies.CONFIG);
  }
};

const getDeviceId = () => {
  getElogEmail();
  getToken();
  getAppSessionId();
  getAppVersion();
  getPlatformName();
  getLinkFromApp();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const deviceIdQueryParam = urlSearchParams.get(queryParamKeys.DEVICE_ID);
  let savedDeviceId = '';

  if (deviceIdQueryParam) {
    Cookies.remove(cookies.DEVICE_ID, {secure: cookies.CONFIG.secure});
    Cookies.set(cookies.DEVICE_ID, deviceIdQueryParam, cookies.CONFIG);
  } else {
    savedDeviceId = Cookies.get(cookies.DEVICE_ID);
  }

  if (typeof savedDeviceId !== 'undefined') return savedDeviceId;

  savedDeviceId = uuid();
  Cookies.set(cookies.DEVICE_ID, savedDeviceId, cookies.CONFIG);
  return savedDeviceId;
};

export {
  getDeviceId
};
