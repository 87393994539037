import {cookies, queryParamKeys} from './constants-enumerate';
import {readingIqTrackerQueue} from './readingiq-tracker-queue';
import Cookies from 'js-cookie';

/**
 *
 * @author Marco Sanchez <marco.sanchez@aofl.com>
 */
class CampaignService {
  /**
   *
   * @param {String} campaignId
   */
  static attribute(campaignId) {
    if (campaignId) {
      campaignId = decodeURIComponent(campaignId);
      const CAMPAIGN_FORMAT_REGEX = /[^\s\t\n:([{]+:[^\s\t\n)\]}]+/i;
      const match = campaignId.match(CAMPAIGN_FORMAT_REGEX);
      if (match !== null) {
        campaignId = match[0];
      }
      const campaignInfo = CampaignService.setCampaignCookie(campaignId);
      CampaignService.fireCampaignClickEvent(campaignInfo);
    }
  }

  /**
   *
   * @param {String} campaignId
   * @return {Object}
   */
  static setCampaignCookie(campaignId) {
    const campaignInfo = {
      'id': campaignId,
      'values': CampaignService.getCampaignValues()
    };

    const cookieConfig = {
      ...cookies.CONFIG,
      'expires': 30
    };
    Cookies.set(cookies.CAMPAIGN_INFO, JSON.stringify(campaignInfo), cookieConfig);
    return campaignInfo;
  }

  /**
   *
   * @param {Object} campaignInfo
   */
  static fireCampaignClickEvent(campaignInfo) {
    const campaignClickEvent = {
      'event_type': 'AOFL.READINGIQ_MARKETING.V1.marketing-campaign-click',
      'payload': {
        'id': campaignInfo.id,
        'values': campaignInfo.values
      }
    };
    readingIqTrackerQueue.enqueueEvent(campaignClickEvent);
  }

  /**
   *
   * @return {Object}
   */
  static getCampaignValues() {
    const campaignValues = {};
    const QUERY_STRING_REGEX = /[^=?&]+=[^&]+/g;
    const PARAMETER_REGEX = /([^=?&]+)=([^&]+)/;
    const parameters = location.search.match(QUERY_STRING_REGEX);

    if (parameters !== null) {
      parameters.forEach((element) => {
        const match = element.match(PARAMETER_REGEX);
        if (match !== null && match[1].toLowerCase() !== queryParamKeys.SOURCE_TAG) {
          campaignValues[match[1]] = decodeURIComponent(match[2]);
        }
      });
    }
    return campaignValues;
  }

  /**
   *
   * @return {Object}
   */
  static getCampaignInfo() {
    const campaignCookie = Cookies.get(cookies.CAMPAIGN_INFO);
    let campaignInfo = {
      'id': null,
      'values': []
    };

    if (campaignCookie) {
      try {
        campaignInfo = JSON.parse(campaignCookie);
      } catch (e) {}
    }
    return campaignInfo;
  }

  /**
   * All adjust campaigns must start with 'mobile_app_install'
   *
   * @param {String} campaignId
   * @return {Mixed}
   */
  static fixAdjustCampaign(campaignId) {
    const ADJUST_REGEX = /^mobile_app_install/i;
    if (campaignId && !ADJUST_REGEX.test(campaignId)) {
      campaignId = 'mobile_app_install::' + campaignId;
    }
    return campaignId;
  }
}

export {
  CampaignService
};
