import {sdoNamespaces, cacheNamespaces} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {storeInstance} from '@aofl/store/dist/legacy';

class UserInfoService {
  /**
   *
   * @static
   */
  static setUserInfo(apiResp) {
    this.setUserSession(apiResp);
    this.setUserState(apiResp);
  }
  /**
   *
   * @static
   */
  static setUserSession(apiResp) {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.setItem(cacheNamespaces.USER_SESSION, {
      userUuid: apiResp.user_uuid, /* eslint-disable-line */
      userAccountType: apiResp.user_account_type, /* eslint-disable-line */
      nextStep: apiResp.step, /* eslint-disable-line */
      isActive: apiResp.is_active /* eslint-disable-line */
    });
  }
  /**
   *
   * @static
   */
  static setUserState(apiResp) {
    storeInstance.commit({
      namespace: sdoNamespaces.SESSION,
      mutationId: 'setUserInfo',
      payload: {
        userUuid: apiResp.user_uuid, /* eslint-disable-line */
        userAccountType: apiResp.user_account_type, /* eslint-disable-line */
        nextStep: apiResp.step, /* eslint-disable-line */
        isActive: apiResp.is_active /* eslint-disable-line */
      }
    });
  }
  /**
   *
   * @static
   */
  static clear() {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.clear();

    storeInstance.commit({
      namespace: sdoNamespaces.SESSION,
      mutationId: 'init'
    });
  }
}

export {
  UserInfoService
};
