import styles from './template.css';
import template from './template';
import {AoflElement, property} from '@aofl/element';
import {mapStatePropertiesMixin} from '@aofl/map-state-properties-mixin';
import {storeInstance} from '@aofl/store/dist/legacy';
import {sdoNamespaces} from '../constants-enumerate';

/**
 * @summary AuthstateContext
 * @extends {mapStatePropertiesMixin(AoflElement)}
 */
class AuthstateContext extends mapStatePropertiesMixin(AoflElement) {
  /**
   * Creates an instance of AuthstateContext.
   */
  constructor() {
    super();
    this.storeInstance = storeInstance;
  }
  /**
   * @readonly
   */
  static is = 'authstate-context';

  /**
   * @readonly
   */
  @property({type: Boolean})
  'show-when-loggedin' = false;

  /**
   *
   */
  mapStateProperties() {
    const state = this.storeInstance.getState();
    if (!state[sdoNamespaces.SESSION].isLoggedIn) {
      if (this['show-when-loggedin']) {
        this.classList.add('hide');
      } else {
        this.classList.remove('hide');
      }
    } else {
      if (this['show-when-loggedin']) {
        this.classList.remove('hide');
      } else {
        this.classList.add('hide');
      }
    }
  }
  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

window.customElements.define(AuthstateContext.is, AuthstateContext);

export default AuthstateContext;
