import {ClickTracker} from '@aoflmkt/tracker-queue';
import {readingIqTrackerQueue} from './readingiq-tracker-queue';
import {uuid} from '@aofl/uuid';
import clickTrackerConfig from './__config/click-tracker';

const clickTrackerMixin = (superClass) => {
  /**
   * @summary ClickTrackerMixin
   * @extends {superClass}
   */
  class ClickTrackerMixin extends superClass {
    /**
     * constructor
     */
    constructor(...args) {
      super(...args);
      this.trackerId = uuid();
    }

    /**
     *
     */
    connectedCallback(...args) {
      super.connectedCallback(...args);

      readingIqTrackerQueue
        .track(this.trackerId, new ClickTracker(clickTrackerConfig, (e, domScope) => {
          if (domScope) {
            readingIqTrackerQueue.enqueueEvent({
              event_type: 'AOFL.READINGIQ_MARKETING.V1.marketing-link-click',
              payload: {
                id: domScope
              }
            });
          }
        }, this.shadowRoot));
    }
    /**
     *
     * @param {any} args
     */
    disconnectedCallback(...args) {
      readingIqTrackerQueue.untrack(this.trackerId);

      super.disconnectedCallback(...args);
    }
  }

  return ClickTrackerMixin;
};

export {
  clickTrackerMixin
};
