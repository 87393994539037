import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {cookies} from '../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';
import {gdprBannerSdo} from './sdo';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

const Mixin = isMobileMixin(clickTrackerMixin(accessibilityMixin(AoflElement)));

/**
 * @summary GdprBanner
 * @class GdprBanner
 * @extends {AoflElement}
 */
@customElement('gdpr-banner')
class GdprBanner extends Mixin {
  /**
   * Creates an instance of SubscriptionActiveUserModal.
   */
  constructor() {
    super();
  }

  /**
   *
   * @readonly
   */
  static is = 'gdpr-banner';

  /**
   *
   * @private
   */
  hideBanner(e) {
    e.preventDefault();
    Cookies.set(cookies.GDPR, 'hide', {
      secure: cookies.CONFIG.secure,
      domain: cookies.CONFIG.domain,
      expires: 90
    });
    gdprBannerSdo.visible = false;
    this.style.display = 'none';
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    if (Cookies.get(cookies.GDPR) === 'show') {
      gdprBannerSdo.visible = true;
    }
    this.style.display = gdprBannerSdo.visible ? 'block' : 'none';
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default GdprBanner;
